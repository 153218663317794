import { useEffect, useMemo, useRef } from 'react';

export const makeLongPolling = (callback: () => Promise<any>, delay: number) => {
  let isActive = true;
  let request: Promise<any> | undefined;
  let timeoutId: NodeJS.Timeout | undefined;

  const cancel = () => {
    isActive = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const longPolling: () => { cancel: () => void; request?: Promise<any> } = () => {
    if (!isActive) {
      return { cancel };
    }
    request = callback().finally(() => {
      request = undefined;
      timeoutId = setTimeout(() => {
        longPolling();
      }, delay);
    });
    return { cancel, request };
  };

  return longPolling;
};

export interface LongPollingOptions {
  deps?: any[];
  shouldWatchCallback?: boolean;
  disabled?: boolean;
}

export const useLongPolling = (callback: () => Promise<any>, delay: number, options?: LongPollingOptions) => {
  const { deps = [], shouldWatchCallback = true, disabled = false } = options || {};

  const dependencies = shouldWatchCallback ? [...deps, callback] : deps;

  const requestRef = useRef<Promise<any> | undefined>();

  const debouncedCallback = useMemo(
    () => makeLongPolling(callback, delay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, delay, disabled]
  );

  useEffect(() => {
    let cancel: () => void;
    if (!disabled) {
      const { cancel: cancelFc, request } = debouncedCallback();
      requestRef.current = request;
      cancel = cancelFc;
    }
    return () => {
      requestRef.current = undefined;
      cancel?.();
    };
  }, [debouncedCallback, disabled]);

  return requestRef.current;
};

export default useLongPolling;
