import { Service } from 'typedi';

import { ClickHistoryData } from 'api/CailagateApi/api/client';

import EventLogger from '../EventLogger';

@Service()
class NavigationLogger extends EventLogger {
  logNavigation = ({ prevUrl, nextUrl }: { prevUrl: string; nextUrl: string }) => {
    if (!this.user) return;
    const navigatioChangeInfo = this.generateNavigationChangeEventInfo({
      prevUrl,
      nextUrl,
    });
    this.log(navigatioChangeInfo);
  };

  private generateNavigationChangeEventInfo = ({
    prevUrl,
    nextUrl,
  }: {
    prevUrl: string;
    nextUrl: string;
  }): ClickHistoryData => {
    return this.generateEventInfo({
      accountId: this.user?.accountId,
      creationTime: new Date().getTime(),
      eventType: 'navigation',
      description: `from ${prevUrl} to ${nextUrl}`,
    });
  };
}

export default NavigationLogger;
