import useLongPolling, { LongPollingOptions } from 'hooks/useLongPolling';
import usePageVisibility from 'hooks/usePageVisibility';

export const useVisibleLongPolling = (callback: () => Promise<any>, delay: number, options?: LongPollingOptions) => {
  const isPageVisible = usePageVisibility();
  const enhancedOptions: LongPollingOptions = { ...(options || {}), disabled: options?.disabled || !isPageVisible };

  return useLongPolling(callback, delay, enhancedOptions);
};

export default useVisibleLongPolling;
