import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import Container from 'typedi';

import InfoBanner, { InfoBannerType } from 'components/InfoBanner';
import Button from 'components/Button';
import { tokens } from 'diConfig';
import { t } from 'localization';

import { useBalanceContext } from 'contexts/BalanceContext';
import styles from './styles.module.scss';

const MANAGER_MAIL_LINK = Container.get(tokens.MANAGER_MAIL_LINK);

const BalanceInfoBanner = () => {
  const { refillByManager, isUserBlocked, isUserBalanceBelowZero, isBillingEnabled, openBalanceRefillModal } =
    useBalanceContext();

  const bannerData:
    | undefined
    | { type?: InfoBannerType; message?: string | React.ReactNode; actions?: () => React.ReactNode } = useMemo(() => {
    if (refillByManager && (isUserBlocked || isUserBalanceBelowZero)) {
      return {
        type: 'danger',
        message: (
          <>
            {t('BalanceInfoBanner:RefillByManager:NoFunds') + ' '}

            <a
              data-test-id='BalanceInfoBanner:ManagerMailLink'
              className={styles.balanceInfoBanner__link}
              href={`mailto:${MANAGER_MAIL_LINK}`}
            >
              <b>{MANAGER_MAIL_LINK}</b>
            </a>
          </>
        ),
      };
    } else if (isUserBlocked) {
      return {
        type: 'danger',
        message: t('BalanceInfoBanner:Danger:NoFunds'),
        actions: () => (
          <Button
            color='danger'
            onClick={openBalanceRefillModal}
            data-test-id='BalanceInfoBanner:OpenBalanceRefillModal'
          >
            {t('BalanceInfoBanner:NoFunds')}
          </Button>
        ),
      };
    } else if (isUserBalanceBelowZero) {
      return {
        type: 'warning',
        message: t('BalanceInfoBanner:Danger:NoFunds'),
        actions: () => (
          <Button
            color='warning'
            onClick={openBalanceRefillModal}
            data-test-id='BalanceInfoBanner:OpenBalanceRefillModal'
          >
            {t('BalanceInfoBanner:NoFunds')}
          </Button>
        ),
      };
    }
    return undefined;
  }, [isUserBalanceBelowZero, isUserBlocked, openBalanceRefillModal, refillByManager]);

  const placeToMountBanner = document.getElementById(Container.get(tokens.BANNER_MOUNT_ID));

  if (!isBillingEnabled || !placeToMountBanner || !bannerData) return null;

  return ReactDOM.createPortal(
    <InfoBanner
      type={bannerData?.type}
      message={bannerData?.message}
      actionRender={bannerData.actions}
      data-test-id='BalanceInfoBanner:BannerWrap'
    />,
    placeToMountBanner
  );
};

export default React.memo(BalanceInfoBanner);
