import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';

import { useDI } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import usePromiseProcessing from 'utils/hooks/usePromiseProcessing';

import { SystemConfigApiService } from 'services/ApiServices/SystemConfigApiService';
import { CaptchaData, ModelGroupData, TaskTypeData } from 'api/CailagateApi/api/client';
import { ModelGroupApiService } from 'services/ApiServices/ModelGroupApiService';

import { AppLogger } from 'services/AppLogger';
import { PERMISSIONS } from 'contexts/AuthContext/constants';

export interface TaskTypeDataMap {
  [key: string]: TaskTypeData;
}

export type ServicesContextType = {
  taskTypesMap?: TaskTypeDataMap;
  taskTypes?: TaskTypeData[];
  getTaskTypeData: (taskTypeName?: string) => TaskTypeData;
  serviceGroups: ModelGroupData[];
  getServiceGroups: () => Promise<void>;
  getServicesSupportInfo: () => Promise<void>;
  deleteServicesGroup: (groupId: number) => Promise<void>;
  editServicesGroup: (name: string) => Promise<void>;
  updateServicesGroup: (name: string, accountId: number, groupId: number) => Promise<void>;
  captchaConfig?: CaptchaData;
};

export const ServicesContext = createContext({} as ServicesContextType);

const defaultTask: TaskTypeData = {
  displayName: '',
  name: '',
};

export const ServicesContextProviderComponent: FC = ({ children }) => {
  const [taskTypes, setTaskTypes] = useState<
    { taskTypesMap?: TaskTypeDataMap; taskTypesRaw?: TaskTypeData[] } | undefined
  >();
  const [serviceGroups, setServiceGroups] = useState<ModelGroupData[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [captchaConfig, setCaptchaConfig] = useState<CaptchaData>();

  const { user, userHasPermission, isUserSuperadmin } = useAuthContext();
  const userIsDeveloper = userHasPermission(PERMISSIONS.MLP_DEVELOPER);
  const userIsAdmin = userHasPermission(PERMISSIONS.MLP_ADMIN);

  const systemConfigApi = useDI(SystemConfigApiService);
  const modelGroupApi = useDI(ModelGroupApiService);

  const [{ loading: loadingGetCaptchaConfig }, getCaptchaConfig] = usePromiseProcessing(
    async () => {
      const { data: captchaData = undefined } = await systemConfigApi.getCaptchaConfig();
      setCaptchaConfig(captchaData);
    },
    [systemConfigApi],
    { onError: error => AppLogger.log({ exception: error }) }
  );

  const [{ loading: loadingGetTaskTypes }, getTaskTypes] = usePromiseProcessing(
    async () => {
      const { data: taskTypes = undefined } = await systemConfigApi.getTaskTypes();
      const taskTypesMap = taskTypes?.reduce((taskTypesMap, taskType) => {
        taskTypesMap[taskType.name] = taskType;
        return taskTypesMap;
      }, {} as TaskTypeDataMap);
      setTaskTypes({ taskTypesMap, taskTypesRaw: taskTypes });
    },
    [systemConfigApi],
    { onError: error => AppLogger.log({ exception: error }) }
  );

  const [{ loading: loadingGetServiceGroups }, getServiceGroups] = usePromiseProcessing(
    async () => {
      if (!user) return;
      if (!userIsDeveloper && !userIsAdmin && !isUserSuperadmin) return;
      const { data: groupsData } = await modelGroupApi.getGroups(user.accountId.toString());
      setServiceGroups(groupsData);
    },
    [isUserSuperadmin, modelGroupApi, user, userIsAdmin, userIsDeveloper],
    { onError: error => AppLogger.log({ exception: error }) }
  );

  const [{ loading: loadingDeleteServicesGroup }, deleteServicesGroup] = usePromiseProcessing(
    async (groupId: number) => {
      if (!user) return;
      await modelGroupApi.deleteGroup(user.shortName, groupId);
      await getServiceGroups();
    },
    [getServiceGroups, modelGroupApi, user],
    { onError: error => AppLogger.log({ exception: error }) }
  );

  const [{ loading: loadingEditServicesGroup }, editServicesGroup] = usePromiseProcessing(
    async (name: string) => {
      if (!user) return;
      await modelGroupApi.createGroup(user.shortName, { name });
      await getServiceGroups();
    },
    [getServiceGroups, modelGroupApi, user],
    { throwOnError: true }
  );

  const [{ loading: loadingUpdateServicesGroup }, updateServicesGroup] = usePromiseProcessing(
    async (name: string, accountId: number, groupId: number) => {
      if (!user) return;
      await modelGroupApi.updateGroup(user.shortName, { name, id: { accountId, groupId } });

      await getServiceGroups();
    },
    [getServiceGroups, modelGroupApi, user],
    { throwOnError: true }
  );

  const getTaskTypeData = useCallback(
    (taskTypeName?: string) =>
      taskTypes?.taskTypesMap && taskTypeName ? taskTypes.taskTypesMap[taskTypeName] : defaultTask,
    [taskTypes?.taskTypesMap]
  );

  const getServicesSupportInfo = useCallback(async () => {
    await Promise.allSettled([getTaskTypes(), getServiceGroups(), getCaptchaConfig()]);
  }, [getCaptchaConfig, getServiceGroups, getTaskTypes]);

  useEffect(() => {
    getServicesSupportInfo().finally(() => setIsInitialized(true));
  }, [getServicesSupportInfo]);

  if (!isInitialized) {
    return null;
  }

  const loading =
    loadingGetCaptchaConfig ||
    loadingGetTaskTypes ||
    loadingGetServiceGroups ||
    loadingDeleteServicesGroup ||
    loadingEditServicesGroup ||
    loadingUpdateServicesGroup;

  return (
    <ServicesContext.Provider
      value={{
        taskTypesMap: taskTypes?.taskTypesMap,
        taskTypes: taskTypes?.taskTypesRaw,
        getTaskTypeData,
        serviceGroups,
        getServiceGroups,
        deleteServicesGroup,
        editServicesGroup,
        getServicesSupportInfo,
        updateServicesGroup,
        captchaConfig,
      }}
    >
      <Spinner zIndex={1000} show={loading} />
      {children}
    </ServicesContext.Provider>
  );
};

export const useServicesContext = () => useContext(ServicesContext);
