import React from 'react';
import useClickLogger from './hooks/useClickLogger';
import useNavigationLogger from './hooks/useNavigationLogger';

const AppEventLoggerCmp = () => {
  useClickLogger();
  useNavigationLogger();

  return null;
};

export default React.memo(AppEventLoggerCmp);
