import { parseFloatOrUndefined } from 'utils';

export function roundNumberWithPrecision(value: number, precision?: number): number {
  if (precision === undefined) return value;
  const fixed = value.toFixed(precision);
  return parseFloat(fixed);
}

export function formatNumberWithPrecision(value: number, precision: number): string {
  const rounded = roundNumberWithPrecision(value, precision);
  return rounded.toString();
}

export function parseStringWithPrecision(value: string, precision: number): number | undefined {
  const num_value = parseFloatOrUndefined(value);
  if (num_value === undefined) return undefined;
  return roundNumberWithPrecision(num_value, precision);
}
