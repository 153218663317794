import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

const CatalogListPage = lazy(() => import('modules/ServicesCatalog/pages/NewCatalogListPage'));
const ServicePage = lazy(() => import('modules/ServicesCatalog/pages/ServicePage'));

export type RouteConfigType = Omit<RouteProps, 'children'>;
export const routesConfig: RouteConfigType[] = [
  {
    path: '',
    component: CatalogListPage,
    exact: true,
  },
  {
    path: ':account/:service',
    component: ServicePage,
  },
];

export const CATALOG_BASE_PATH = 'catalog';
export const DEFAULT_PATH = '';
