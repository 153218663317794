import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Constructable, Container } from 'typedi';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';

import Spinner from 'components/Spinner';
import MessageContainer, { notifyActionMessage } from 'components/MessageContainer';

import { CurrencyTypeEnum, FrontendSettings, LanguageEnum } from 'api/CailagateApi/api/client';
import { SystemConfigApiService } from 'services/ApiServices/SystemConfigApiService';

import { parseError, isProd, stringToBoolean } from 'utils';
import { StorageKeeper } from 'services/StorageKeeper';
import { useAppLanguage } from './hooks';
import AppHelmet from './components/AppHelmet';

registerPreloadedIcons([
  'faTimes',
  'farTrashAlt',
  'faChevronUp',
  'faChevronDown',
  'farChevronLeft',
  'farChevronRight',
  'farCheckCircle',
  'farCopy',
  'farArrowToTop',
  'faCheckCircle',
  'farCheck',
  'farExclamationCircle',
  'faExternalLink',
  'farWindowMinimize',
  'farWindowMaximize',
  'farTimes',
  'farUpload',
  'faTimes',
  'farPlus',
  'farMinus',
  'farEdit',
  'farPen',
  'farQuestionCircle',
  'farCreditCard',
  'faSpinner',
  'farCube',
  'farArrowRight',
  'farArrowToBottom',
  'faMicrophone',
  'faCaretDown',
  'falTrashAlt',
  'farFolder',
  'farGlobe',
  'farCircle',
  'farUserGraduate',
  'farTh',
  'farCode',
  'farBook',
  'farChartLine',
  'farBooks',
  'farImage',
  'farFile',
  'farICursor',
  'farSpinner',
  'faQuestionCircle',
]);

const initMediaRecorder = async () => {
  register(await connect());
};

export interface AppContextType extends FrontendSettings {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  diContainer: typeof Container;
  handleError: (error: any, useThrottle?: boolean) => void;
  changeLanguage: (language?: LanguageEnum) => Promise<void>;
  language: LanguageEnum;
  isImmers: boolean;
  isTovieMLP: boolean;
  canonicalHref?: string;
}

const TOVIEMLP_STORAGE_KEY = 'isTovieMLP';
const tovieMLPStorageKeeper = new StorageKeeper(TOVIEMLP_STORAGE_KEY);
const isTovieMLPHostname = window.location.hostname.toLowerCase()?.includes('tovie');

const IMMERS_STORAGE_KEY = 'isImmers';
const immersStorageKeeper = new StorageKeeper(IMMERS_STORAGE_KEY);
const isImmersHostname = window.location.hostname.toLowerCase()?.includes('immers');

const defaultFrontendSettings: FrontendSettings = {
  isBillingEnabled: false,
  isExtendedLanding: false,
  isSystemAccount: false,
  isArchiveEnabled: false,
  currencyType: CurrencyTypeEnum.TOKEN,
  englishOnly: true,
  refillByManager: false,
  saveClicksEnabled: false,
};

export const AppContext = createContext({} as AppContextType);

export const AppContextProviderComponent: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useAppLanguage();
  const errorSourcesRef = useRef<Set<string>>(new Set<string>());
  const systemConfigApi = Container.get(SystemConfigApiService);

  const [isImmersStorageFlag, setIsImmersStorageFlag] = useState<boolean>(false);
  const [isTovieMLPStorageFlag, setIsTovieMLPStorageFlag] = useState<boolean>(false);

  const [frontendSettings, setFrontendSettings] = useState<FrontendSettings | undefined>(undefined);

  const getFrontendSettings = useCallback(async () => {
    setLoading(true);
    let settings: FrontendSettings;

    try {
      const { data } = await systemConfigApi.getFrontendSettings();
      setFrontendSettings(data);
      settings = data;
    } catch (error) {
      setFrontendSettings(defaultFrontendSettings);
      settings = defaultFrontendSettings;
    }
    setLoading(false);
    return settings;
  }, [systemConfigApi]);

  const handleError = useCallback((error: any, useThrottle = false) => {
    const errorMessage = parseError(error);
    if (!useThrottle) {
      notifyActionMessage(errorMessage, { type: 'error' });
    } else if (!errorSourcesRef.current.has(errorMessage)) {
      errorSourcesRef.current.add(errorMessage);
      setTimeout(() => errorSourcesRef.current.delete(errorMessage), 30000);
      notifyActionMessage(errorMessage, { type: 'error' });
    }
  }, []);

  const [isAppLaunching, setIsAppLaunching] = useState(true);

  const init = useCallback(async () => {
    Promise.allSettled([setLanguage(), initMediaRecorder()])
      .then(async () => {
        const settings = await getFrontendSettings();
        if (settings.englishOnly) {
          setLanguage(LanguageEnum.EN);
        }
      })
      .then(() => {
        immersStorageKeeper.get().then(storageValue => {
          if (storageValue.success) {
            setIsImmersStorageFlag(stringToBoolean(storageValue?.payload?.toString() || ''));
          }
        });
        tovieMLPStorageKeeper.get().then(storageValue => {
          if (storageValue.success) {
            setIsTovieMLPStorageFlag(stringToBoolean(storageValue?.payload?.toString() || ''));
          }
        });
      });
  }, [setLanguage, getFrontendSettings]);

  useEffect(() => {
    init().finally(() => setIsAppLaunching(false));
  }, [init]);

  const changeLanguage = useCallback(
    async (language?: LanguageEnum) => {
      if (!frontendSettings) return;
      await setLanguage(frontendSettings.englishOnly ? LanguageEnum.EN : language);
    },
    [frontendSettings, setLanguage]
  );

  const isTovieMLP = isTovieMLPStorageFlag || isTovieMLPHostname;
  const isImmers = isImmersStorageFlag || isImmersHostname;

  const canonicalHref = isTovieMLP ? 'https://mlp.tovie.ai' : isProd() ? 'https://caila.io' : undefined;

  if (isAppLaunching || !frontendSettings) return null;

  return (
    <AppContext.Provider
      value={{
        diContainer: Container,
        loading,
        language,
        canonicalHref,
        setLoading,
        handleError,
        changeLanguage,
        isImmers,
        isTovieMLP,
        ...frontendSettings,
      }}
    >
      <AppHelmet isTovieMLP={isTovieMLP} canonicalHref={canonicalHref} />
      {children}
      <Spinner zIndex={1000} show={loading} />
      <MessageContainer />
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export const useDI = <CONTAINER_TYPE extends unknown>(type: Constructable<CONTAINER_TYPE>) =>
  useAppContext().diContainer.get(type);
