import { useEffect, useState } from 'react';

function usePageVisibility(): boolean {
  const [visibilityState, setVisibilityState] = useState<'hidden' | 'visible'>(document.visibilityState);

  useEffect(() => {
    const handleVisibilityChange = () => setVisibilityState(document.visibilityState);

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return visibilityState === 'visible';
}

export default usePageVisibility;
