import { throttle } from 'lodash';
import { Inject, Service } from 'typedi';

import { AccountInfoData, ClickHistoryData } from 'api/CailagateApi/api/client';
import { ClickHistoryApiService } from 'services/ApiServices/ClickHistoryApiService';

import { AppLogger } from 'services/AppLogger';

const SAVE_EVENT_DELAY = 5_000;

@Service()
class EventLogger {
  //TODO: more appropriate backend api service name required
  @Inject(() => ClickHistoryApiService) clickHistoryApi!: ClickHistoryApiService;

  queue: ClickHistoryData[] = [];
  timeoutId: NodeJS.Timeout | undefined;
  user: AccountInfoData | undefined;

  public setUser(user?: AccountInfoData) {
    this.user = user;
  }

  public log = (eventInfo: ClickHistoryData) => {
    if (!this.user) return;

    this.queue?.push(eventInfo);
    this.saveEvent();
  };

  protected saveEvent = throttle(
    () => {
      if (!this.queue?.length) return;
      this.clickHistoryApi
        .saveClick(this.queue, this.user?.accountId?.toString())
        .catch(error => AppLogger.log({ exception: error }));
      this.queue = [];
    },
    SAVE_EVENT_DELAY,
    { leading: false, trailing: true }
  );

  protected generateEventInfo = (
    eventData: Omit<ClickHistoryData, 'creationTime' | 'pageUrl'> &
      Partial<Pick<ClickHistoryData, 'pageUrl' | 'creationTime'>>
  ): ClickHistoryData => {
    return {
      pageUrl: window.location.href,
      creationTime: new Date().getTime(),
      ...eventData,
    };
  };
}

export default EventLogger;
