import React from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';

type ErrorFallbackProps = {
  onRetry: () => void;
};

const ErrorFallback = ({ onRetry }: ErrorFallbackProps) => {
  return (
    <div className={styles.errorFallback} role='alert'>
      <div className={styles.errorFallback__title}>{t('ErrorFallback:Text')}</div>
      <Button data-test-id='ErrorFallback:Retry' color='primary' onClick={onRetry} className='margin-top-2x'>
        {t('ErrorFallback:TryAgain')}
      </Button>
    </div>
  );
};

export default React.memo(ErrorFallback);
