import { Service } from 'typedi';

import { ClickHistoryData } from 'api/CailagateApi/api/client';

import EventLogger from '../EventLogger';

const getElementIdInfo = (el?: HTMLElement | null) =>
  el?.getAttribute('data-test-id') || el?.getAttribute('id') || el?.getAttribute('name') || '';

const watchedElements: {
  name: string;
  checkNode: (node: EventTarget | null) => boolean;
  log: (node: EventTarget | null) => string;
}[] = [
  {
    name: 'button',
    checkNode(target: EventTarget | null): target is HTMLButtonElement {
      return !!(target as HTMLElement | null)?.closest('button');
    },
    log(target: EventTarget | null) {
      const element = (target as HTMLElement | null)?.closest('button');
      return getElementIdInfo(element);
    },
  },
  {
    name: 'link',
    checkNode(target: EventTarget | null): target is HTMLAnchorElement {
      return !!(target as HTMLElement | null)?.closest('a');
    },
    log(target: EventTarget | null) {
      const element = (target as HTMLElement)?.closest('a');
      return getElementIdInfo(element);
    },
  },
  {
    name: 'input',
    checkNode(target: EventTarget | null): target is HTMLAnchorElement {
      return !!((target as HTMLElement | null)?.tagName === 'INPUT');
    },
    log(target: EventTarget | null) {
      return getElementIdInfo(target as HTMLElement | null);
    },
  },
  {
    name: 'switch',
    checkNode(target: EventTarget | null): target is HTMLAnchorElement {
      const element = target as HTMLElement | null;
      return !!(element?.classList.contains('switch-label') || element?.classList.contains('switch-handle'));
    },
    log(target: EventTarget | null) {
      const element = target as HTMLElement | null;
      const input = element?.parentElement?.children
        ? Array.from(element?.parentElement?.children)?.find(sibling => sibling.tagName === 'INPUT')
        : '';
      return input ? getElementIdInfo(input as HTMLElement) : '';
    },
  },
];

interface ClickInfoInterface extends Pick<ClickHistoryData, 'elementId'> {}
interface ClickSourceInfoInterface {
  elementType?: string;
}
@Service()
class ClickLogger extends EventLogger {
  logClick = (event: MouseEvent) => {
    if (!this.user) return;

    const target = event.target;
    if (!target) return;
    const watcherConf = watchedElements.find(el => el.checkNode(target));

    if (watcherConf) {
      const clickInfo = this.generateFullClickInfo({
        elementType: watcherConf.name,
        elementId: watcherConf.log(target),
      });
      this.log(clickInfo);
    }
  };

  private generateFullClickInfo = ({
    elementType,
    elementId,
  }: ClickInfoInterface & ClickSourceInfoInterface): ClickHistoryData => {
    return this.generateEventInfo({
      accountId: this.user?.accountId,
      eventType: 'click',
      description: elementType,
      elementId,
    });
  };
}

export default ClickLogger;
