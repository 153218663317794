import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';
import ControlledResizableTextArea from 'components/ControlledResizableTextArea';

import { DemoRequestFormFieldsType, DemoRequestFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import styles from './styles.module.scss';
import Container from 'typedi';
import { tokens } from 'diConfig';

interface DemoRequestFormInterface {
  formMethods: DemoRequestFormMethodsType;
}

export const useDemoRequestForm = (
  props?: UseFormProps<DemoRequestFormFieldsType>
): UseFormReturn<DemoRequestFormFieldsType, any> => {
  return useForm<DemoRequestFormFieldsType>({
    criteriaMode: 'all',
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    ...props,
  });
};

export default function DemoRequestForm({ formMethods }: DemoRequestFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  const tgLink = Container.get(tokens.TELEGRAM_SUPPORT_LINK);

  return (
    <FormContainer
      className={styles.demoRequestForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      <span>
        {t('DemoRequestModal:Message')}{' '}
        <a target='_blank' rel='noreferrer noopener' href={tgLink} data-test-id='DemoRequestForm:Link:Telegram'>
          {t('DemoRequestModal:MessageTelegram')}
        </a>
      </span>
      <ControlledInput
        title={t('DemoRequestModal:Form:Name')}
        data-test-id='DemoRequestModal:Form:name'
        name='name'
        placeholder={t('DemoRequestModal:Form:NamePlaceholder')}
        control={control}
      />

      <div className={styles.demoRequestForm__row}>
        <ControlledResizableTextArea
          title={t('DemoRequestModal:Form:Contacts')}
          data-test-id='DemoRequestModal:Form:contacts'
          name='contacts'
          placeholder={t('DemoRequestModal:Form:ContactsPlaceholder')}
          control={control}
        />
      </div>
    </FormContainer>
  );
}
