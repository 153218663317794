import { useEffect } from 'react';
import Container from 'typedi';

import ClickLogger from '../../../../services/Loggers/ClickLogger';

import { useAuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';

const clickLogger = Container.get(ClickLogger);

const useClickLogger = () => {
  const { user } = useAuthContext();
  const { saveClicksEnabled } = useAppContext();

  useEffect(() => {
    clickLogger.setUser(user);
  }, [user]);

  useEffect(() => {
    const log = (event: MouseEvent) => clickLogger.logClick(event);
    if (saveClicksEnabled) {
      document.body.addEventListener('click', log);
    }
    return () => {
      document.body.removeEventListener('click', log);
    };
  }, [saveClicksEnabled]);
};

export default useClickLogger;
